body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  font-family: "GilroyRegular", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-rendering: geometricPrecision;
  text-align: left;
  color: #3a3838;
  background: #fff;
  word-break: break-word;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: GilroyBlack;
  src: local('GiloryBlack'), url('./assets/fonts/Gilroy-Black.otf') format('opentype');
}

@font-face {
  font-family: GiloryExtraBold;
  src: local('GiloryExtraBold'), url('./assets/fonts/Gilroy-ExtraBold.otf') format('opentype');
}

@font-face {
  font-family: GilorySemiBold;
  src: local('GilorySemiBold'), url('./assets/fonts/Gilroy-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: GiloryMedium;
  src: local('GiloryMedium'), url('./assets/fonts/Gilroy-Medium.otf') format('opentype');
}

@font-face {
  font-family: GilroyRegular;
  src: local('GilroyRegular'), url('./assets/fonts/Gilroy-Regular.otf') format('opentype');
}

@font-face {
  font-family: GiloryLight;
  src: local('GiloryLight'), url('./assets/fonts/Gilroy-Light.otf') format('opentype');
}

@font-face {
  font-family: GilroyThin;
  src: local('GilroyThin'), url('./assets/fonts/Gilroy-Thin.otf') format('opentype');
}

/* import bootstrap to set changes */
// @import '~bootstrap/scss/bootstrap';
@import 'bootstrap/dist/css/bootstrap.min.css';