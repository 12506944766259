.header {
    height: 6rem;
    margin-bottom: 1rem;
}

.title {
    margin-top: 2rem;
    line-height: 1em;
}

.profile {
    text-align: right;
}

.userName {
    padding-top: 1.5rem;
    margin-bottom: 0;
}

.logo {
    width: 3rem;
    margin-top: 1.5rem;
}

.logoutButton {
    margin-bottom: 0;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: #00abf5;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #00ADEE;
    --bs-btn-border-color: #00ADEE;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0059B4;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #00ADEE;
    --bs-btn-disabled-border-color: #00ADEE;
}

.logAccessForm {
    padding: 1.5rem 1.5rem 2.5rem 1.5rem;
    background-color: #efefef;
    margin-bottom: 2rem;
}

.reportTitle {
    margin-bottom: 0.7rem;
    font-weight: bold;
}

.reportList {
    margin-bottom: 2rem;
}

.fileName {
    cursor: pointer;
}

.noData {
    text-align: center;
}

.downloadButton {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.footer {
    text-align: center;
    margin-bottom: 2rem
}

.loadingButton {
    margin-right: '5px' !important;
    padding-right: '5px' !important;
}

